<template>
  <div class="settings-wrapper">
    <div class="settings-header">
      <div class="title">Profile</div>
      <ActionButton
        v-if="!isLoadingSettings"
        :width="'151px'"
        :height="'44px'"
        :top="'50px'"
        :text="'Save'"
        :class="{ 'action-btn-not-active': isNewValuesPresent }"
        @click="saveSettings"
      />
      <div
        class="action-btn loading-indicator-container"
        v-if="isLoadingSettings"
      >
        <div class="spinner"></div>
      </div>
    </div>
    <div class="user-profile">
      <div class="error-container">{{ userDataErrorMessage }}</div>
      <div class="common-field-container">
        <div class="common-lable">Email</div>
        <div class="common-field">{{ currentUser.email }}</div>
      </div>
      <CommonInputField
        :width="'320px'"
        :height="'65px'"
        :inputHeight="'54px'"
        :lable="'Full Name'"
        :placeholder="'John Doe'"
        :value="newUserFullName"
        @updateValue="setNewUserName($event)"
      />
      <CommonInputField
        :width="'320px'"
        :height="'65px'"
        :inputHeight="'54px'"
        :lable="'Phone'"
        :placeholder="'+1(050)124-214-12'"
        :value="newUserPhone"
        @updateValue="setNewUserPhone($event)"
      />
      <CommonInputField
        :width="'320px'"
        :height="'65px'"
        :inputHeight="'54px'"
        :lable="'Company'"
        :placeholder="'Enter company name'"
        :value="newUserCompany"
        @updateValue="setNewUserCompany($event)"
      />
    </div>
    <div class="settings-header" v-if="isCompanySettingsShown">
      <div class="title">Company</div>
      <ActionButton
        v-if="!isCompanyUpdateLoading"
        :width="'151px'"
        :height="'44px'"
        :top="'50px'"
        :text="'Save'"
        :class="{ 'action-btn-not-active': isCompanyUpdatePresent }"
        @click="updateCompanyData"
      />
      <div
        class="action-btn loading-indicator-container"
        v-if="isCompanyUpdateLoading"
      >
        <div class="spinner"></div>
      </div>
    </div>
    <div class="company-settings" v-if="isCompanySettingsShown">
      <div class="error-container">{{ companyErrorMessage }}</div>
      <CommonInputField
        :width="'320px'"
        :height="'65px'"
        :inputHeight="'54px'"
        :lable="'Company name'"
        :placeholder="'Company name'"
        :value="newCompanyName"
        @updateValue="setNewCompanyName($event)"
      />
    </div>
    <div class="change-password-header">
      <div class="title-password">Change Password</div>
      <ActionButton
        v-if="!isLoading"
        :width="'151px'"
        :height="'44px'"
        :top="'50px'"
        :text="'Save'"
        :class="{ 'action-btn-not-active': isNewPasswordPresent }"
        @click="changePassword"
      />
      <div class="action-btn loading-indicator-container" v-if="isLoading">
        <div class="spinner"></div>
      </div>
    </div>
    <div class="password-wrapper">
      <div class="error-container">{{ passwordErrorMessage }}</div>
      <!-- Old Password field -->
      <div class="input-container password-container">
        <div class="field-label">Old Password</div>
        <input
          :type="passwordFieldType"
          class="input-field"
          v-model="userPassword"
        />
        <div
          class="password-visibility-btn"
          :class="{
            'password-visible-icon': passwordFieldType === 'password',
            'password-invisible-icon': passwordFieldType === 'text',
          }"
          @click="switchPasswordVisibility"
        ></div>
      </div>
      <!-- New Password field -->
      <div class="input-container password-container">
        <div class="field-label">New Password</div>
        <input
          :type="passwordFieldType"
          class="input-field"
          v-model="newUserPassword"
        />
        <div
          class="password-visibility-btn"
          :class="{
            'password-visible-icon': passwordFieldType === 'password',
            'password-invisible-icon': passwordFieldType === 'text',
          }"
          @click="switchPasswordVisibility"
        ></div>
      </div>
      <!-- Reenter Password field -->
      <div class="input-container password-container">
        <div class="field-label">Confirm New Password</div>
        <input
          :type="passwordFieldType"
          class="input-field"
          v-model="reenterUserPassword"
        />
        <div
          class="password-visibility-btn"
          :class="{
            'password-visible-icon': passwordFieldType === 'password',
            'password-invisible-icon': passwordFieldType === 'text',
          }"
          @click="switchPasswordVisibility"
        ></div>
      </div>
    </div>
    <div class="logout-btn-container" v-if="isCompanySettingsShown">
      <ActionButton
        :width="'151px'"
        :minHeight="'44px'"
        :height="'44px'"
        :top="'50px'"
        :text="'Logout'"
        :class="{ 'logout-btn': true }"
        @click="handleB2BLogout"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import CommonInputField from './CommonInputField.vue'
import ActionButton from './ActionButton.vue'

import { UserRole } from '../../utils/constants'

export default {
  components: {
    CommonInputField,
    ActionButton,
  },
  data() {
    return {
      passwordFieldTypes: {
        PASSWORD: 'password',
        TEXT: 'text',
      },
      passwordFieldType: '',
      errorMessage: '',
      userDataErrorMessage: '',
      companyErrorMessage: '',
      passwordErrorMessage: '',
      isLoadingSettings: false,
      isCompanyUpdateLoading: false,
      isLoading: false,
      errorState: false,
      newUserFullName: '',
      newUserEmail: '',
      newUserPhone: '',
      newUserCompany: '',
      newCompanyName: '',
      userPassword: '',
      newUserPassword: '',
      reenterUserPassword: '',
      successMessage: '',
      isNewValuesPresent: false,
      isNewPasswordPresent: false,
      isCompanyUpdatePresent: false,
      UserRole: UserRole,
    }
  },
  async created() {
    await this.setInitialValue()
  },
  watch: {
    successMessage(newValue, _) {
      if (newValue) {
        setTimeout(() => (this.successMessage = ''), 2000)
      }
    },
    errorMessage(newValue, _) {
      if (newValue) {
        setTimeout(() => (this.errorMessage = ''), 2000)
      }
    },
    reenterUserPassword(newValue, _) {
      if (newValue) this.isNewPasswordPresent = true
      if (!newValue) this.isNewPasswordPresent = false
    },
  },
  computed: {
    ...mapState(['currentUser', 'company']),
    isCompanySettingsShown() {
      return (
        this.currentUser.role === this.UserRole.B2BOwner &&
        this.$route.name === 'B2BSettings'
      )
    },
  },
  methods: {
    ...mapActions([
      'updateUser',
      'changeUserPassword',
      'getCompany',
      'updateCompany',
      'signOut',
    ]),
    async getCompanyData() {
      await this.getCompany()
    },
    async setInitialValue() {
      this.passwordFieldType = this.passwordFieldTypes.PASSWORD
      this.newUserFullName = this.currentUser.full_name
      this.newUserPhone = this.currentUser.phone
      this.newUserCompany = this.currentUser.represented_company

      if (this.isCompanySettingsShown) {
        !this.company && (await this.getCompanyData())
        this.newCompanyName = this.company.name
      }
    },
    setNewCompanyName(newCompanyName) {
      this.isCompanyUpdatePresent = true
      this.errorMessage = ''
      this.newCompanyName = newCompanyName
    },
    async updateCompanyData() {
      if (!this.newCompanyName) {
        this.companyErrorMessage = 'Company name is required'
        return
      }

      this.isCompanyUpdateLoading = true

      try {
        this.companyErrorMessage = ''
        await this.updateCompany({
          name: this.newCompanyName,
          id: this.company.id,
        })
      } catch (error) {
        this.companyErrorMessage = 'Something went wrong. Please try again.'
      } finally {
        this.isCompanyUpdatePresent = false
        this.isCompanyUpdateLoading = false
      }
    },
    async changePassword() {
      if (
        !this.userPassword ||
        !this.newUserPassword ||
        !this.reenterUserPassword
      ) {
        this.passwordErrorMessage = 'Please, fill password fields'
        return
      }

      if (this.newUserPassword !== this.reenterUserPassword) {
        this.passwordErrorMessage =
          'The passwords should be equal and should be at least 8 characters long.'
        return
      }

      const passwords = {
        old_password: this.userPassword,
        new_password: this.newUserPassword,
      }

      this.isLoading = true

      try {
        this.passwordFieldType = this.passwordFieldTypes.PASSWORD

        await this.changeUserPassword(passwords)

        this.passwordErrorMessage = ''
      } catch (error) {
        this.passwordErrorMessage = error.response.data.detail
      } finally {
        this.isLoading = false
        this.isNewPasswordPresent = false
      }
    },
    async saveSettings() {
      if (!this.newUserFullName) {
        this.userDataErrorMessage = 'Full name field is required'
        return
      }
      if (
        this.newUserFullName === this.currentUser.full_name &&
        this.newUserPhone === this.currentUser.phone &&
        this.newUserCompany === this.currentUser.represented_company
      ) {
        return
      }

      const updated_user = {
        id: this.currentUser.id,
        full_name: this.newUserFullName,
        phone: this.newUserPhone,
        represented_company: this.newUserCompany,
      }

      this.isLoadingSettings = true

      try {
        this.userDataErrorMessage = ''
        await this.updateUser(updated_user)
      } catch (error) {
        this.userDataErrorMessage = 'Something went wrong, please try again.'
      } finally {
        this.isLoadingSettings = false
        this.isNewValuesPresent = false
      }
    },
    switchPasswordVisibility() {
      if (this.passwordFieldType === this.passwordFieldTypes.PASSWORD) {
        this.passwordFieldType = this.passwordFieldTypes.TEXT
      } else {
        this.passwordFieldType = this.passwordFieldTypes.PASSWORD
      }
    },
    setNewUserName(newUserFullName) {
      this.isNewValuesPresent = true
      this.errorMessage = ''
      this.newUserFullName = newUserFullName
    },
    setNewUserPhone(newUserPhone) {
      this.isNewValuesPresent = true
      this.errorMessage = ''
      this.newUserPhone = newUserPhone
    },
    setNewUserCompany(newUserCompany) {
      this.isNewValuesPresent = true
      this.errorMessage = ''
      this.newUserCompany = newUserCompany
    },
    async handleB2BLogout() {
      await this.signOut()
      this.$router.push({ name: 'B2BSignIn' })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../assets/styleVars.scss';

.settings-wrapper {
  width: 100%;
  max-width: 720px;
  height: 720px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px 0;
  background-color: $whiteColor;
  overflow-y: scroll !important;

  .action-button {
    margin-top: 0;
  }
}

@media (max-height: 900px) {
  .settings-wrapper {
    height: 600px;
    padding-bottom: 70px;
  }
}

.error-container {
  width: 100%;
  height: 24px;
  min-height: 24px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  color: $redWarningColor;
}

.error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $redWarningColor;
  z-index: 999999 !important;
  position: fixed !important;
  right: 26px;
  left: 26px;
  top: 96px;
  padding: 16px;
  border-radius: 2px;
}

.success-message {
  display: flex;
  justify-content: center;
  align-items: center;
  color: green;
  z-index: 999999 !important;
  position: fixed !important;
  right: 26px;
  left: 26px;
  top: 96px;
  padding: 16px;
  border-radius: 2px;
}

.change-password-header {
  width: 90%;
  height: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 24px;

  .action-button {
    background-color: $grayBorderColor;
    color: $fontColorBlack;
  }

  .action-btn {
    width: 151px;
    height: 45px;
    margin-top: 20px;
    min-height: 45px;
    display: flex;
    justify-content: center;
    background-color: $grayBorderColor;
    color: $fontColorBlack;
    cursor: pointer;
  }

  .action-btn-not-active {
    background-color: $appActionColor;
  }

  .loading-indicator-container {
    cursor: wait;

    &:hover {
      background-color: $grayBorderColor;
    }

    .spinner {
      margin-top: 10px;
      border: 5px solid $whiteColor;
      border-radius: 50%;
      border-top: 5px solid $appActionColor;
      width: 15px;
      height: 15px;
      animation: spin 2s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.settings-header {
  width: calc(100% - 80px);
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $fontColorBlack;
  margin-left: 24px;

  .action-button {
    background-color: $grayBorderColor;
    color: $fontColorBlack;
  }

  .action-btn-not-active {
    background-color: $appActionColor;
  }

  .action-btn {
    width: 151px;
    height: 45px;
    margin-top: 20px;
    min-height: 45px;
    display: flex;
    justify-content: center;
    background-color: $grayBorderColor;
    color: $fontColorBlack;
    cursor: pointer;
  }

  .loading-indicator-container {
    cursor: wait;

    &:hover {
      background-color: $grayBorderColor;
    }

    .spinner {
      margin-top: 10px;
      border: 5px solid $whiteColor;
      border-radius: 50%;
      border-top: 5px solid $appActionColor;
      width: 15px;
      height: 15px;
      animation: spin 2s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: $fontSizeModalTitle;
  }
}

.user-profile {
  width: calc(100% - 80px);
  height: fit-content;
  margin-left: 24px;
  // margin-top: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  position: relative;
  margin-bottom: 12px;

  .common-input-lable {
    height: 24px;
    font-size: $fontSizeStandart;
  }

  .common-input-field-container {
    margin-top: 20px;
  }

  .common-field-container {
    width: 320px;
    height: 54px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .common-lable {
      height: 24px;
      font-size: $fontSizeMinified;
    }

    .common-field {
      width: 100%;
      padding: 4px;
      font-size: $fontSizeStandart;
    }
  }
}

.company-settings {
  width: calc(100% - 80px);
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  position: relative;
  margin-bottom: 12px;

  .common-input-lable {
    height: 24px;
    font-size: $fontSizeStandart;
  }
}

.title-password {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: $fontSizeModalTitle;
}

.password-wrapper {
  width: calc(100% - 80px);
  height: 250px;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .field-label {
    color: $fontColorBlack;
    font-size: $fontSizeStandart;
  }

  .input-field {
    width: 320px;
    height: 44px;
    padding: 0 6px;
    font-size: $fontSizeStandart;
    border: 1px solid $grayBorderColor;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .input-container {
    width: 320px;
  }

  & > .input-container {
    width: 320px;
  }

  & > .input-container ~ .input-container {
    margin-top: 20px;
  }

  .password-container {
    position: relative;

    .password-visibility-btn {
      width: 24px;
      height: 24px;
      position: absolute;
      top: calc(100% - 34px);
      right: 12px;
      height: 24px;
      background-size: 24px;
      background-position: center;
      cursor: pointer;
    }

    .password-invisible-icon {
      background-image: url('../../assets/icons/visibility-off-icon.svg');
    }

    .password-visible-icon {
      background-image: url('../../assets/icons/visibility-on-icon.svg');
    }
  }
}

.logout-btn-container {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
  margin-left: 24px;

  .logout-btn {
    background-color: $redWarningColor;

    &:hover {
      background-color: $hoveredRedWarninngColor;
    }
  }
}

@media (max-width: 420px) {
  .error-message {
    margin-top: 75px;
    margin-left: 45px;
    position: fixed;
  }

  .success-message {
    margin-top: 75px;
    margin-left: 45px;
    position: fixed;
  }

  .main-contents {
    width: 365px !important;
    margin-left: 12px;
    overflow-y: scroll !important;
  }

  .settings-header {
    width: 320px;
    margin-left: 0;
  }

  .user-profile {
    margin-top: 0px;
    margin-left: 0;
    width: 320px;
  }

  .company-settings {
    margin-top: 0px;
    margin-left: 0;
    width: 320px;
  }

  .change-password-header {
    margin-left: 0;
    width: 320px;
  }

  .password-wrapper {
    margin-top: 10px;
    margin-left: 0;
    width: 320px;
  }
}
</style>
